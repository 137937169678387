<template>
	<div class="main_calendar_settings">
		<CreateCalendarPage 
			:isForm="true" 
			:calendarSettings="calendarSettings"/>
	</div>
</template>

<script>
// import CMS from '../../../service/cms/service';
export default {
	name: 'MainSettings',
	components: {
        CreateCalendarPage: () => import('../../pages/CreateCalendar.vue')
    },
	
	computed: {
		calendarSettings(){
			const calendar = this.$store.getters.currentCalendar()
			if (!calendar)
				return undefined
			return calendar.settings
		}
	},
};
</script>

<style scoped>
.main_calendar_settings {
	margin-top: 0;
	padding-top: 48px
}

.main_calendar_settings > .create__calendar {
	padding: 0;
	margin-top: 0;
}
</style>